<template>
    <div>
        <b-modal
            id="dom-config"
            size="xl"
            header-class="head_info"
            footer-class="head_info"
            no-close-on-backdrop
            no-close-on-esc
            centered>

            <template #modal-header>
                <h4 class="text-white mb-0">Domain Configuration</h4>
            </template>

            <div class="container-fluid">
                <div class="row p-2">
                    <div class="col-12 d-flex justify-content-end">
                        <span class="mt-1">Max Domains: {{ plans.NumberOfDomains }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-12">
                        <b-tabs card>
                            <b-tab title="Domain 1">
                                <div class="row">
                                    <div class="col-6">
                                        <b-form-select class="form-control" v-model="domainData.domain_id" style="background-color: #EBEBEB; border-radius: 0px;">
                                            <b-form-select-option value="" selected>-- Select Domain --</b-form-select-option>
                                            <option v-for="domain in domains" :key="domain._id" :value="domain._id">{{ domain.name }}</option>
                                        </b-form-select>
                                    </div>
                                    <div class="col-6">
                                        <b-row>
                                            <b-col cols="3" class="pt-1">
                                                <label class="">Query String</label>
                                            </b-col>
                                            <b-col cols="9">
                                                <b-input-group class="mb-3 " size="sm">
                                                    <b-form-input id="QueryString" type="text" v-model="domainData.QueryString" placeholder="" autocomplete="off"></b-form-input>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </div>

                                </div>
                                <b-row class="mt-2">
                                    <b-col class="bg-light pt-4" >
                                        <b-row>
                                            <b-col cols="4" class="pt-1">
                                                <label class="">Start Date</label>
                                            </b-col>
                                            <b-col cols="8">
                                                <b-input-group class="mb-3 " size="sm">
                                                    <b-form-input id="StartDate" type="text" v-model="domainData.StartDate.split('T')[0]" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                    <b-input-group-append >
                                                        <b-form-datepicker button-only right locale="en-US" v-model="domainData.StartDate" aria-controls="StartDate" size="sm"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col class="bg-light pt-3 ml-2" cols="5" >
                                        <b-row>
                                            <b-col cols="4" class="pt-1">
                                                <label class="">Stop Date</label>
                                            </b-col>
                                            <b-col cols="7">
                                                <b-input-group class="mb-3 " size="sm">
                                                    <b-form-input id="StopDate" v-model="domainData.StopDate.split('T')[0]" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                    <b-input-group-append >
                                                        <b-form-datepicker button-only right locale="en-US" v-model="domainData.StopDate" aria-controls="StopDate" size="sm"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col class="bg-light pt-4" >

                                        <b-row>
                                            <b-col cols="5" class="pt-1">
                                                <label>Start Time</label>
                                            </b-col>
                                            <b-col  cols="7">
                                                <b-input-group size="sm" class="mr-5">
                                                    <b-form-input  v-mask="'##:##'" v-model="domainData.StartTime" type="text" size="sm" autocomplete="off" placeholder="00:00"></b-form-input>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                    <b-col class="bg-light pt-3 ml-2" cols="5" >
                                        <b-row>
                                            <b-col cols="5" class="pt-1">
                                                <label>Stop Time</label>
                                            </b-col>
                                            <b-col  cols="7">
                                                <b-input-group size="sm" class="mr-5">
                                                    <b-form-input  v-mask="'##:##'" v-model="domainData.StopTime" type="text" size="sm" autocomplete="off" placeholder="00:00"></b-form-input>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col class="bg-light pt-4" >
                                        <b-row>
                                            <b-col cols="5" class="pt-1">
                                                <label>Number of Clicks</label>
                                            </b-col>
                                            <b-col cols="7">
                                                <b-input-group size="sm" class="mr-5">
                                                    <b-form-input type="number" v-model="domainData.NumberOfClicks" size="sm" autocomplete="off" min="0"></b-form-input>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col class="bg-light pt-4" >

                                        <b-row>
                                            <b-col cols="10" class="pt-1">
                                                <label>Is google analytics required</label>
                                            </b-col>
                                            <b-col  cols="2">
                                                <b-form-checkbox v-model="domainData.googleanalytics.isrequired" name="check-button" switch/>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                    <b-col v-if="domainData.googleanalytics.isrequired" class="bg-light pt-3 ml-2" cols="8" >
                                        <b-row>
                                            <b-col cols="5" class="pt-1">
                                                <label>Google analytics source</label>
                                            </b-col>
                                            <b-col  cols="7">
                                                <b-input-group size="sm" class="mr-5">
                                                    <b-form-input @keypress="limitSpecial($event)" v-model="domainData.googleanalytics.source" type="text" size="sm" autocomplete="off" />
                                                </b-input-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>
                                <!----->
                                <!-- TOS button and Component -->
                                <b-row>
                                    <div class="col-12 pr-5 pl-4">
                                        <div class="row mt-3 mb-2 d-flex justify-content-center">
                                            <b-button class="mr-2" v-if="domainData._id" variant="info" size="sm" @click="tos = true">Configure TOS</b-button>
                                            <b-button v-if="domainData._id" variant="dark" size="sm" @click="calendar(domainData)">View Calendar</b-button>
                                        </div>

                                        <div class="row d-flex justify-content-center " >
                                            <div class="col-12 col-md-12 d-flex justify-content-center">
                                                <b-button  class="" variant="success" @click="saveDomainConfig()"><strong>Save Configuration</strong></b-button>
                                            </div>
                                        </div>

                                    </div>
                                    <t-o-s v-if="tos" :linklvlTOS="domainData.linkLevelTos" :domain_config_id="domainData._id" v-on:close="tos = false" v-on:modified="tos = false; tosNotify()"></t-o-s>
                                </b-row>

                                <b-row v-if="plans.PlanListing" class="pt-2">
                                    <b-col style="background-color: #dee2e6">
                                        <p class="pt-2">Proxy Type: {{plans.PlanListing.ProxyType}}</p>
                                    </b-col>
                                </b-row>

                                <div id="Residentialcity" v-if="plans.PlanListing.ProxyType === 'ResidentialCity' && domainData._id">
                                    <div class="row mt-4">
                                        <div class="col-4 pl-4 pr-4">
                                            <select v-model="locate.country" @change="Getstates();"  class="form-control">
                                                <option value="" selected>-- Any country --</option>
                                                <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4 pl-4 pr-4">
                                            <select v-model="locate.state" @change="Getcities();"  class="form-control">
                                                <option value="" selected>-- Any state --</option>
                                                <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-4" v-if="locate.state">
                                        <div class="col-11 col-md-11 pl-4 pr-4" >
                                            <ejs-multiselect id='multiselectcity' v-model="locate.city" :fields="cityFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                        </div>
                                        <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.city !== ''">
                                            <b-button size="sm" variant="success" @click="addGeos( domainData._id )" v-text="'Add'" />
                                        </div>
                                    </div>
                                </div>
                                <div id="Residentialstate" v-if="plans.PlanListing.ProxyType === 'ResidentialState' && domainData._id">
                                    <div class="row mt-4">
                                        <div class="col-4 pl-4 pr-4">
                                            <select v-model="locate.country" @change="Getstates();"  class="form-control">
                                                <option value="" selected>-- Any country --</option>
                                                <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-4" v-if="locate.country">
                                        <div class="col-11 col-md-11 pl-4 pr-4" >
                                            <ejs-multiselect id='multiselect' v-model="locate.state" :fields="stateFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='states'></ejs-multiselect>
                                        </div>
                                        <div class="col-1 pl-0 d-flex justify-content-center" v-if="locate.state.length !== 0">
                                            <b-button size="sm" variant="success" @click="addGeos( domainData._id )" v-text="'Add'" />
                                        </div>
                                    </div>
                                </div>
                                <div id="DataCenter" v-if="plans.PlanListing.ProxyType === 'Data Center' && domainData._id">
                                    <div class="row mt-4">
                                        <div class="col-11 col-md-11 pl-4 pr-4" >
                                            <ejs-multiselect id='multiselect' v-model="locate.country" :fields="dataFields" :allowFiltering="true" placeholder="Select Countries" :dataSource='countries'></ejs-multiselect>
                                        </div>

                                        <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.country.length !== 0">
                                            <b-button size="sm" variant="success" @click="addGeos( domainData._id )" v-text="'Add'" />
                                        </div>
                                    </div>
                                </div>
                                <div id="Mobile" v-if="plans.PlanListing.ProxyType === 'Mobile' && domainData._id">
                                    <div class="row mt-4">
                                        <div class="col-4 pl-4 pr-4">
                                            <select v-model="locate.country" @change="Getstates();" class="form-control">
                                                <option value="" selected>-- Any country --</option>
                                                <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4 pl-4 pr-4">
                                            <select v-model="locate.state" @change="Getcities();" class="form-control">
                                                <option value="" selected>-- Any state --</option>
                                                <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-4" v-if="locate.state">
                                        <div class="col-11 col-md-11 pl-4 pr-4" >
                                            <ejs-multiselect id='multiselect' v-model="locate.city" :fields="mobileFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                        </div>
                                        <div class="col-1 col-md-1 pl-0 d-flex justify-content-center">
                                            <b-button size="sm" variant="success" @click="addGeos( domainData._id )" v-text="'Add'" />
                                        </div>
                                    </div>
                                    <hr>
                                    <b-table
                                        striped
                                        :fields="fields"
                                        ref="selectedRow"
                                        :items="domainData.geos">

                                        <!-- removed v-on:send-city="getcity" -->
                                        <template v-slot:cell(city)="row">
                                            <citySelect :domid="domainData._id" :id="row.item._id" :value="row.item.city" :index="row.index" :country="row.item.country"   :state="row.item.state"></citySelect>
                                        </template>

                                        <template v-slot:cell(Actions)="row" >
                                            <b-button @click="removeGeos( row.index,row.index, domainData._id, row.item._id )"  v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                        </template>

                                    </b-table>
                                </div>
                                <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'ResidentialCity' && domainData._id">
                                    <b-table
                                        striped
                                        :fields="fields"
                                        ref="selectedRow"
                                        :items="domainData.geos">

                                            <!-- removed v-on:send-city="getcity" -->
                                        <template v-slot:cell(city)="row"> 
                                            <citySelect :value="row.item.city"  :domid="domainData._id" :id="row.item._id" :index="row.index"   :country="row.item.country"   :state="row.item.state"></citySelect>
                                        </template>

                                        <template v-slot:cell(Actions)="row">
                                            <b-button @click="removeGeos(row.index, domainData._id, row.item._id)"  v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                        </template>

                                    </b-table>
                                </div>
                                <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'ResidentialState' && domainData._id">
                                    <b-table
                                        striped
                                        :fields="RSfields"
                                        ref="selectedRow"
                                        :items="domainData.geos">

                                        <template v-slot:cell(state)="row">
                                            <stateSelect :domid="domainData._id" :id="row.item._id"  :index="row.index" :country="row.item.country" v-on:send-state="getstate" :state="row.item.state"></stateSelect>
                                        </template>

                                        <template v-slot:cell(Actions)="row" >
                                            <b-button @click="removeGeos(row.index, domainData._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                        </template>

                                    </b-table>
                                </div>
                                <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'Data Center' && domainData._id">
                                    <b-table
                                        striped
                                        :fields="datFields"
                                        ref="selectedRow"
                                        :items="domainData.geos">
                                        <template v-slot:cell(country)="row">
                                            <countrySelect :domid="domainData._id" :id="row.item._id" :value="row.item.country" :index="row.index" v-on:send-country="getcountry"></countrySelect>
                                        </template>

                                        <template v-slot:cell(Actions)="row" >
                                            <b-button @click="removeGeos(row.index, domainData._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                        </template>

                                    </b-table>
                                </div>

                                <div class="row p-2" v-if="domainData.status === 'active'">
                                    <div class="col-12 text-center">

                                        <b-button variant="dark ml-3" size="sm" class="font-weight-bold"
                                                  to="/clients" @click="$router.push({ path: '/resultReporting', query: { dom_id: domainData._id, product_type: domainData.product_type } })">
                                            Client Report </b-button>
                                    </div>
                                </div>
                                <b-row class="d-flex justify-content-center">
                                    <div class="row p-3" v-if="domainData.geos">
                                        <b-button size="sm" @click="configGeo( domainData._id )"  variant="info">Configure Geos</b-button>
                                    </div>
                                    <div class="row p-3" v-if="domainData.geos && domainData.geos.length !== 0 && domainData.status !== 'active'">
                                        <div class="col-12 col-md-12 d-flex justify-content-center">
                                            <b-button class="" @click="generateCampaign( domainData._id )"><strong>Generate Campaign</strong></b-button>
                                        </div>
                                    </div>

                                    <b-alert v-if="domainData.status === 'active'" variant="warning" show>Campaign for <strong>{{ domainData.domain }}</strong> has been generated. No further editing permitted.</b-alert>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>

                <configure-geo v-if="configureGeo" :domain="domainData" :domain_nm="domainsNM" :plan="plans" :noc="Number(domainData.NumberOfClicks)" :geos="geoConfig" v-on:closegeos="configureGeo = false" />

            </div>

            <template #modal-footer>
                <div>
                    <b-button variant="dark" size="sm" class="font-weight-bold" @click="$emit('closedc'); $bvModal.hide('dom-config');" style="border-radius: 0px !important;">BACK</b-button>
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import axios from "axios";
import citySelect from "../citySelect";
import stateSelect from "../stateSelect";
import countrySelect from "../countrySelect";
import ConfigureGeo from '@/components/domain/configuration/geos'
import TOS from '@/components/domain/configuration/tos'
import swal from "sweetalert";
import {mapMutations} from "vuex";
Vue.use(VueMask);


export default {
    name: "configure",
    components: { citySelect, stateSelect, countrySelect, ConfigureGeo, TOS },
    data() {
        return {
            configureGeo: false,
            tos: false,
            countries: '',
            geoConfig: '',
            domainsNM: [],
            states: '',
            cities: '',
            currentEndTime: '',
            locate: {
                country: '',
                state: '',
                city: '',
            },
            client:{},
            countryFields:{ text: 'country_name', value: 'country_name' },
            cityFields: { text: 'city_name', value: 'city_name' },
            stateFields: { text: 'city_name', value: 'state_name' },
            dataFields: { text: 'name', value: 'code' },
            mobileFields: { text: 'city_name', value: 'city_name' },
        }
    },
    props: {
        domainData: {
            type: Object,
            required: true
        },
        plans: {
            type: Object,
            required: true
        },
        domains: {
            type: Array,
            required: true
        },
    },
    created() {
        this.loadcountries();
        this.getClient();
        this.getClientDomains()
        this.currentEndTime  = this.domainData.StopTime

        //if statement
        this.domainData.NumberOfClicks == 0 ?
            this.domainData.NumberOfClicks = this.plans.PlanListing.NumberOfClicks || this.domainData.NumberOfClicks
            :this.domainData.NumberOfClicks

        console.log({data:this.domainData})
        if(this.domainData.googleanalytics === null || this.domainData.googleanalytics === undefined){
            this.domainData.googleanalytics = {
                isrequired: false,
                source: "",
                unqiue: "Adretreaver"
            }
        }

        this.domainData.StartDate = this.domainData.StartDate.split('T')[0]
        this.domainData.StopDate = this.domainData.StopDate.split('T')[0]
        console.log({data:this.domainData})
    },
    watch: {
        domainData() {
            this.reset()
        }
    },
    computed: {
        fontSize() {
            return 50 - (120/(12-this.domain.TimeOnSite.length))
        },
        fields() {
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'state',
                    label: 'State',
                    sortable: false
                },
                {
                    key: 'city',
                    label: 'City',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
        RSfields() {
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'state',
                    label: 'State',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
        datFields(){
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        ...mapMutations(['setDomain']),
        limitSpecial(e){
            if(!e.key.match(/^[a-zA-Z0-9]*$/))
            {
                e.preventDefault();
            }
        },
        async getClient() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/clients/${this.plans.ClientId}`)

            if (resp.data && resp.data.error) {
                swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                this.disabled = false;
            } else {
                this.client = resp.data.data
                this.domainData.timezone = this.client.TimeZone
            }
        },

        tosNotify() {
            this.$bvToast.toast('TOS updated successfully', {
                title: <strong>Information: TOS Updated</strong>,
                variant: 'info',
                solid: true
            })
            this.getDomain()
        },
        getClientDomains() {
            axios.get(`${this.$root.serverUrl}/admin/domain/domain/verified/${this.plans.ClientId}` ).then((resp) => {
                this.domainsNM = resp.data.data || []
            })
        },
        configGeo(id) {
            axios.get(`${this.$root.serverUrl}/admin/domain/click-distribution/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    console.log(resp)
                } else {
                    this.geoConfig = resp.data.data
                    this.configureGeo = true;
                }
            })
        },
        getcity( item ) {
            
            if(item.city.length !== 0 && item.state.length !== 0 && item.country.length != 0)
            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if ( resp.data ) {
                    this.$bvToast.toast('Geos updated Successfully', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
              
        },
        getstate( item ) {
        
            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if ( resp.data ) {
                    this.$bvToast.toast('Geos updated Successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
        },
        getcountry( item ) {
         
            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if ( resp.data ) {
                    this.$bvToast.toast('Geos updated Successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
        },
        calendar(domain) {
            this.setDomain(domain);
            this.$router.push('/click/schedule')
        },
        loadcountries: function(){
            var request = {'apikey': this.$root.apikey, sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        Getstates: function(){
            this.states = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.locate.country) request.filters.country_iso_code = this.locate.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        Getcities: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.locate.country) request.filters.country_iso_code = this.locate.country;
            if(this.locate.state) request.filters.subdivision_1_iso_code = this.locate.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        addGeos( id ) {
            this.locate.domainId = id
    
            axios.put(`${this.$root.serverUrl}/admin/domain/geos`, this.locate).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Information: Geos Not Updated</strong>,
                        variant: 'warning',
                        solid: true
                    })
                } else {
                    // console.log(this.domainData.geos)
                // this.$emit('callUpdate')
                    this.domainData.geos.push({...resp.data.data})
                   
                    // location.reload()
                }
            })
        },
        removeGeos( idx, dom, id  ) {
            
            
            axios.delete(`${this.$root.serverUrl}/admin/domain/geos/${dom}/${id}`).then((resp) => {
                if ( !resp.error ) {
              this.domainData.geos.splice(idx, 1)
                    this.$bvToast.toast('Geos Deleted successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                    // this.$emit('callUpdate')
                } else {
                    this.$bvToast.toast('An Error Occurred.', {
                        title: <strong>ERROR</strong>,
                        variant: 'warning',
                        solid: true
                    })
                }
                
            })
        },
        generateCampaign(dom) {
            axios.put(`${this.$root.serverUrl}/admin/domain/campaigns/${dom}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Campaign not generated.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    swal({ title: "Campaign has been generated.", text: "Campaign Generated.", icon:"success" } ).then(() => { location.reload() })
                }
            })
        },
        validateDate(date) {
            // let reg = /(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/;
            let reg = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
            if (!reg.test(date)) {
                this.$bvToast.toast('You entered an invalid Date. Format: YYYY-MM-DD', {
                    title: <strong>Warning: Time on Site</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        validateAll() {
            if ( !this.domainData.domain_id || !this.domainData.StartTime || !this.domainData.StopTime || !this.domainData.StartDate || !this.domainData.StopDate ) {
                return false
            } else { return true }
        },
        validateCorrectness() {
            if ( !this.validateTimeHrMinStart(this.domainData.StartTime) || !this.validateTimeHrMinStop(this.domainData.StopTime) || !this.validateDate(this.domainData.StartDate) || !this.validateDate(this.domainData.StopDate) || !this.validateClickAmt(this.domainData.NumberOfClicks) ) {
                return false
            } else { return true }
        },
        validateTimeHrMinStart(time) {
            let reg = /^([0-6]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!reg.test(time)) {
                this.$bvToast.toast('You entered an invalid time. Format: HH:MM', {
                    title: <strong>Warning: Start Time</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        validateTimeHrMinStop(time) {
            let reg = /^([0-6]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!reg.test(time)) {
                this.$bvToast.toast('You entered an invalid time. Format: HH:MM', {
                    title: <strong>Warning: Stop Time</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        validateClicks() {
            let sum = 0
            for ( let i = 0; i < this.domains.length; i++ ) {
                sum += Number(this.domains[i].NumberOfClicks)
            }

            let balance = Number(this.plans.PlanListing.NumberOfClicks) - sum

            if ( sum < Number(this.plans.PlanListing.NumberOfClicks) ) {
                this.$bvToast.toast(`You went below the allotted Number of Clicks for this plan. Please select at least ${balance} more across your domains.`, {
                    title: <strong>Warning: Amount of Clicks</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        reset() {
            this.locate.country = ''
            this.locate.city = ''
            this.locate.state = ''
        },
        validateClickAmt(value) {
            if ( Number(value) <= 0 ) {
                this.$bvToast.toast('Please enter a Click Amount greater than 0.', {
                    title: <strong>Warning: Number of Clicks</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {  return true  }
        },
        async saveDomainConfig() {
            if ( this.validateCorrectness() && this.validateAll() ) {

                if(this.domainData.status == 'active') {

                    let confirm = "A campaign is currently running for this configuration. Do you still want to edit this file? Changes will be applied once campaign is in-active"

                    swal({
                        title: 'Are you sure?',
                        text: confirm,
                        icon: 'warning',
                        buttons:true,
                        dangerMode:true
                    }).then(async (edit) => {
                        if(edit) {
                            let time = this.currentEndTime.split(':')
                            let hour = parseInt(time[0]) + 1


                            if(hour >= 24) hour = '00'

                            let resp = await axios.post(`${this.$root.serverUrl}/admin/domain/shelf`, this.domainData)


                            if (resp.data && resp.data.error) {
                                swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                                this.disabled = false;
                            } else {
                                let message = `The campaign for this creative is currently running. Creative changes will take effect at ${hour}:${time[1]}`

                                swal({
                                    title: 'Success',
                                    text: message,
                                    icon: 'success'
                                }).then(() => {
                                    this.disabled = false;
                                    location.reload()
                                })
                            }
                        }
                    })


                }  else {

                    let method = (this.domainData._id) ? 'post' : 'put';

                    axios[method](`${this.$root.serverUrl}/admin/domain`, { params: { domains: this.domainData } }).then((resp) => {
                        if ( resp.data && resp.data.error ) {
                            swal({ title: 'Error', text: resp.data.error.message, icon: 'error' })
                        } else {
                            swal({ title: 'Success', text: 'Domain configured successfully.', icon: 'success' }).then(() => {
                                location.reload()
                            })
                        }
                    })
                }


            } else {
                this.$bvToast.toast('Please check form for discrepancies.', {
                    title: <strong>Error: Form has errors.</strong>,
                    variant: 'danger',
                    solid: true
                })
            }
        },
        updateDomainConfig() {
            if ( this.validateCorrectness() && this.validateAll() ) {
                axios.post(`${this.$root.serverUrl}/admin/domain`, { params: { domains: this.domainData } } ).then((resp) => {
                    console.log(resp)
                    if ( resp.data && resp.data.error ) {
                        swal({ title: 'Error', text: resp.data.error.message, icon: 'error' })
                    } else {
                        swal({ title: 'Success', text: 'Domain configured successfully.', icon: 'success' }).then(() => {
                            // location.reload()
                            this.$emit('callUpdate')
                        })
                    }
                })
            } else {
                this.$bvToast.toast('Please check form for discrepancies.', {
                    title: <strong>Error: Form has errors.</strong>,
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}
</script>

<style scoped>

/deep/ .head_info {
    background-color: #444444;
}

</style>
