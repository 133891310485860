<template>
    <div class="container-fluid p-0">
        <div class="row p-3" style="background-color: #444444">
            <div class="col-6">
                <h3 class="text-white mb-0">Domain Management</h3>
            </div>
        </div>

        <div class="row p-2" style="background-color: #EBEBEB">
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/clients' })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'ALL CLIENTS'"/>
            </div>
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/clients', query: { edit: '1', id: client._id } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'CLIENT MANAGEMENT'"/>
            </div>
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/product/management', query: { client_id: client._id } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'PRODUCT MANAGEMENT'"/>
            </div>
        </div>
        <div class="row border-bottom">
            <div class="col-12 col-md-12">
                <p class="m-2"><strong>Client Name: {{ client.Name }}</strong></p>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-4">
                <p class="m-2"><strong>Plan Type: {{ plans.ProductType }}</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2" v-if="plans.PlanListing"><strong>Proxy Type: {{ plans.PlanListing.ProxyType }}</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2"><strong>Plan Size:</strong></p>
            </div>
        </div>

      

        <div class="row pt-3" style="background-color: #EBEBEB">
              <div class="mt-2 mb-2 col-12 col-md-12  text-right ">
             <b-button  class="btn btn-dark ml-1" v-b-tooltip.hover.top title="View Report" size="sm" @click="$router.push({ path: '/domain/management', query: { plan_id: row.item._id } })"><span class="fa fa-file"></span></b-button>
        </div>
            <div class="col-12 col-md-12">
                <b-table
                    style="background-color: #ffffff"
                    :fields="fields"
                    :items="domains"
                    hover
                    :select-mode="'single'"
                    selectable
                    @row-clicked="domains => $set( domains, '_showDetails', !domains._showDetails )">

                    <template #row-details="row">
                        <b-row>
                            <b-col class="text-center">
                                <b-button class="text-center btn-dark text-white" size="sm" @click="configure = true; editData = row.item" v-b-modal.dom-config>
                                    {{ !row.item._showDetails ? 'Hide' : 'Edit' }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>

                    <template v-slot:cell(name)="row">
                        <div>{{ domainName(row.item.domain_id) }}</div>
                    </template>

                    <template v-slot:cell(created_ts)="row">
                        <div>{{ row.item.created_ts }}</div>
                    </template>

                    <template v-slot:cell(modified_ts)="row">
                        <div>{{ row.item.modified_ts }}</div>
                    </template>

                    <template v-slot:cell(geos)="row">
                        <b-button variant="primary" @click="configGeos(row.index), editData = row.item" size="sm" v-if="row.item.geos">{{ row.item.geos.length }}</b-button>
                        <b-button variant="primary" @click="configGeos(row.index)" size="sm" v-else>0</b-button>
                    </template>

                    <template v-slot:cell(linkLevelTos)="row">
                        <h5><b-badge variant="info" size="lg">{{ row.item.linkLevelTos.length }}</b-badge></h5>
                    </template>

                </b-table>
            </div>
        </div>

        <div class="row d-flex justify-content-center p-3">
            <b-button @click="incrementDomain" v-b-modal.dom-config>Add Domain</b-button>
        </div>

        <footerDesign first-button="Add a Product" first-button-show="false"  second-button="Manage Domains"
                      second-button-show="false" third-button="Manage Products" third-button-show="false">

        </footerDesign>
        <configure v-if="configure" :plans="plans" :domains="domainsNM" :domainData="editData" v-on:closedc="configure = false" v-on:callUpdate="getDomain();" />
        <configure-geo v-if="configureGeo" :domain="editData" :domain_nm="domainsNM" :plan="plans" :geos="editData.geos" :noc="Number(editData.NumberOfClicks)" :nocd="editData.NumberOfClicksDelivered" v-on:closegeos="configureGeo = false" />
    </div>
</template>

<script>
import Configure from '@/components/domain/configuration/configure'
import ConfigureGeo from '@/components/domain/configuration/geos'
import footerDesign from "@/components/style_Component/footerDesign";
import {mapMutations} from "vuex";
import swal from "sweetalert";

import axios from "axios";
export default {
    name: "DomainManagement",
    props: ['user'],
    data() {
        return {
            configure: false,
            client: '',
            editData: '',
            plans: {},
            domains: [],
            domainsNM: [],
            configureGeo: false,
            tos: false,
            geoConfig: [],
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'name',
                    label: 'Domain Name',
                    sortable: true
                },
                {
                    key: 'domain_id',
                    label: 'Domain ID',
                    sortable: false
                },
                {
                    key: 'created_ts',
                    label: 'Date Added',
                    sortable: true
                },
                {
                    key: 'modified_ts',
                    label: 'Date Updated',
                    sortable: false
                },
                {
                    key: 'StartDate',
                    label: 'Start Date',
                    sortable: false
                },
                {
                    key: 'StopDate',
                    label: 'End Date',
                    sortable: false
                },
                {
                    key: 'StartTime',
                    label: 'Start Time',
                    sortable: false
                },
                {
                    key: 'StopTime',
                    label: 'Stop Time',
                    sortable: false
                },
                {
                    key: 'linkLevelTos',
                    label: 'Link Level',
                    sortable: false
                },
                {
                    key: 'clickReq',
                    label: 'Clicks Requested',
                    sortable: false
                },
                {
                    key: 'clickDel',
                    label: 'Clicks Delivered',
                    sortable: false
                },
                {
                    key: 'perClickDel',
                    label: '% of Del. Clicks',
                    sortable: false
                },
                {
                    key: 'geos',
                    label: 'No. Of Geos',
                    sortable: false,
                },
                {
                    key: 'clickDist',
                    label: 'Daily Click Dist.',
                    sortable: false
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                }
            ]
        },
    },
    components: {
        Configure,
        ConfigureGeo,
        footerDesign
    },
    filters: {
        convert: function(value) {
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else {
                return value / 1000000 + 'M'
            }
        },
        convertG: function(value) {
            return value / 1000000000 + 'G'
        }
    },
    created() {
        this.loadPlans().finally(() => {
            this.getClientDomains()
            this.getDomain()
            this.$root.preloader = false;
        })
    },
    methods: {
        domainName(id) {
            let name
            for( let x in this.domainsNM ) {
                if ( this.domainsNM[x]._id === id ) {
                    name = this.domainsNM[x].name
                }
            }
            return name
        },
        getClient(id) {
            axios.get(`${this.$root.serverUrl}/admin/clients/${id}`).then((resp) => {

                if(resp.data && !resp.data.error) {
                    swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                } else {
                    this.client =  resp.data.data
                    this.editData.timezone = this.client.TimeZone
                }

            })
        },
        updateCompleted() {
            this.$bvToast.toast('Domain updated successfully.', {
                title: <strong>Information: Domain Updated</strong>,
                variant: 'info',
                solid: true
            })
        },
        configGeos(index) {
            if ( this.domains[index].geos ) {
                this.configureGeo = true
            } else {
                this.$bvToast.toast('No Geos Configured', {
                    title: <strong>Warning: No Geos</strong>,
                    variant: 'warning',
                    solid: true
                })
            }
        },
        ...mapMutations(['setDomain']),
        getClientDomains() {
            axios.get(`${this.$root.serverUrl}/admin/domain/domain/verified/${this.plans.ClientId}` ).then((resp) => {
                this.domainsNM = resp.data.data || []
            })
        },
        calendar(domain) {
            this.setDomain(domain);
            this.$router.push('/click/schedule')
        },
        configGeo(id) {
            axios.get(`${this.$root.serverUrl}/admin/domain/click-distribution/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    console.log(resp)
                } else {
                    this.geoConfig = resp.data.data
                    this.configureGeo = true;
                }
            })
        },
        loadPlans() {
            return axios.get(`${this.$root.serverUrl}/admin/clients/product/${this.$route.query.plan_id}`).then((resp) => {
                //Store the stats
                this.plans = (resp.data && resp.data.data)? resp.data.data : {};
                this.getClient(this.plans.ClientId)
                this.$forceUpdate();
            });
        },
        incrementDomain() {
            const total = (this.plans.PlanListing) ? this.plans.PlanListing.NumberOfDomains : 0
            if ( this.domains.length !== Number(total) ) {
                let object = Object.assign({}, {
                    NumberOfClicks: this.plans.PlanListing.Driven === 'click' ? this.plans.PlanListing.NumberOfClicks : 0,
                    domain_id: '',
                    StartTime: '',
                    StopTime: '',
                    StartDate: '',
                    StopDate: '',
                    geos: [],
                    googleanalytics:{
                        isrequired: true,
                        source: "",
                        unqiue: "Adretreaver"
                    }
                });
                object.plan_id =  this.plans.PlanId;
                object.client_id =  this.plans.ClientId;
                object.product_id =  this.$route.query.plan_id;

                this.editData = object
                this.configure = true


                // this.domains.push(object)
            }
            if( this.domains.length === Number(total) ) {
                this.$bvToast.toast('You have reached the Maximum amount of allotted domains.', {
                    title: <strong>Information: Maximum domains</strong>,
                    toaster: 'b-toaster-top-center',
                    variant: 'info',
                    solid: true
                })
            }
        },
        getDomain() {
            let request = {'apikey': this.$root.apikey};
            return axios.get(`${this.$root.serverUrl}/admin/domain/product/${this.$route.query.plan_id}`, {params:request}).then((resp) => {
                this.domains = resp.data.data || []
                if ( this.configure ) {
                    for( let data of this.domains ) {
                        if ( data._id === this.editData._id ) {
                            if(data.googleanalytics === null || data.googleanalytics === undefined){
                                this.data.googleanalytics = {
                                    isrequired: true,
                                    source: "",
                                    unqiue: "Adretreaver"
                                }
                            }
                            this.editData = data
                        }
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
.form-control:focus {
    -webkit-box-shadow: 0 0 0 0rem;
    box-shadow: 0 0 0 0rem;
    border-color: #CED4DA;
}
</style>
